<template>
    <v-card flat color="transparent" class="sponsorship clickable" @click="openUrl" v-if="advert">

        <!-- Obligatory Ad Placement Notice -->
        <v-card-title class="pt-0 pb-0 pr-0 pl-1">
            <p class="caption font-weight-medium mb-0">{{ $t('ad.notice') }}</p>
        </v-card-title>

        <!-- Fullwidth Image -->
        <v-img class="grey lighten-2" :src="advert.storagePath + advert.photo" v-if="advert.type === 'image-only' || advert.type === 'detail'">
            <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-layout>
            </template>
        </v-img>

        <!-- Fullwidth Text -->
        <v-card-text class="pt-1 pb-1 pl-1" v-if="advert.type === 'text-only' || advert.type === 'detail'">
            <h6 class="body-1 font-weight-bold">{{ advert.title }}</h6>
            <p class="caption">{{ advert.description }}</p>
        </v-card-text>

        <!-- Small Image -->
        <v-container class="pt-1 pb-1 pl-1" v-if="advert.type === 'small-image'">
            <v-layout row>
                <v-flex xs4>
                    <v-img class="grey lighten-2"
                           contain
                           :src="advert.storagePath + advert.photo">
                        <template v-slot:placeholder>
                            <v-layout fill-height align-center justify-center ma-0>
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-layout>
                        </template>
                    </v-img>
                </v-flex>
                <v-flex xs8 class="pt-0 pl-1">
                    <h6 class="body-1 font-weight-bold">{{ advert.title }}</h6>
                    <p class="caption">{{ advert.description }}</p>
                </v-flex>
            </v-layout>
        </v-container>

    </v-card>
</template>

<script>
    export default {
        name: "InfoAdvert",
        props: {
            placement: String
        },
        data() {
            return {
                advert: false
            }
        },
        beforeMount() {
            this.getAd();
        },
        methods: {
            getAd() {
                axios.get('/api/sponsorship/' + this.placement)
                    .then(res => {
                        // Set ad data if available
                        // Save impression
                        if (res.data.id) {
                            this.advert = res.data;
                            this.saveImpression();
                        }

                        // Else, remove component data
                        else this.advert = false;
                    });
            },
            saveImpression() {
                axios.post('/api/sponsorship/' + this.advert.id);
            },
            openUrl() {
                window.open('/sponsorship/click/' + this.advert.id);
            }
        }
    }
</script>

<style scoped lang="scss">
    .sponsorship {
        min-width: 240px;
        max-width: 460px;
        margin: 1em;
    }

    .sponsorship:hover {
        background-color: white;

        .body-1 {
            color: #0d47a1;
            text-decoration: underline;
        }
    }
</style>