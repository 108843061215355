import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import VueI18n from 'vue-i18n'
import _ from 'lodash'
import axios from 'axios'

// BS
window.axios = axios;
window.Vue = Vue;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('CSRF token not found.');
}

// Components
Vue.component('info-advert', require('../app/components/ads/InfoAdvert').default);

// Helpers
function usernameRule(value) {
  const pattern = /^[a-zA-Z0-9_]{1,20}$/;
  return pattern.test(value) || 'Invalid username'
}

// Vue uses
Vue.use(Vuetify, {
  iconfont: 'fa',
  theme: {
    primary: process.env.MIX_APP_COLOR
  }
});
Vue.use(VueI18n);
Vue.prototype.Fudrr = window.Fudrr;
Vue.prototype.usernameRule = usernameRule;

// Languages
const i18n = new VueI18n({
  locale: window.Fudrr.user.locale,
  fallbackLocale: 'en',
  messages: {}
});

window.loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

function loadLanguageAsync(lang, force = false) {
  if (force === true || i18n.locale !== lang && !loadedLanguages.includes(lang)) {
    return axios.get(`/api/lang/${lang}`)
      .then(msgs => {
        i18n.setLocaleMessage(lang, msgs.data);
        loadedLanguages.push(lang);
        return Promise.resolve(setI18nLanguage(lang));
      });
  }
  return Promise.resolve(lang)
}

// Init app
const auth = new Vue({
  i18n,
  data: () => ({
    user: null,
    showPassword: false,
    age: {
      dialog: false
    },
    loading: {
      ready: false
    },
    login: {
      remember: false
    },
    registration: {
      over13: false,
      wrapMember: false,
      step: null,
      loading: false,
      valid: true,
      hasErrors: false,
      acceptTerms: false,
      form: {
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        locale: 'en',
        password: null,
        password_confirmation: null,
        photo: null,
        invite_token: null,
        where_from: null,
        zip: null,
        city: null,
        wrapMemberGroup: null,
      },
      errors: {
        first_name: [],
        last_name: [],
        username: [],
        email: [],
        locale: [],
        password: [],
        password_confirmation: [],
        where_from: [],
        zip: [],
        city: [],
      },
    }
  }),
  beforeCreate() {
    loadLanguageAsync(window.Fudrr.user.locale, true)
      .then(() => {
        this.loading.ready = true;
      });
  },
  mounted() {

    // Set pre-login path if available
    window.localStorage.preLoginPath = window.location.hash.substr(1, window.location.hash.length);

    // Reload page every 5 minutes
    setInterval(() => {
      location.reload();
    }, 450000);
  },
  methods: {
    firstStepComplete(type) {
      this.registration.over13 = true;
      this.registration.wrapMember = type;
      this.registration.step = 1;
    },
    swapLanguage() {
      loadLanguageAsync(this.registration.form.locale, true).then(() => {
        // Set RTL to true if non-english
        if (['iw', 'ar'].indexOf(this.registration.form.locale) !== -1) {
          this.$vuetify.rtl = true;
        } else {
          this.$vuetify.rtl = false;
        }
      });
    },
    goBack() {
      this.registration.step = 1;
      this.registration.hasErrors = false;
      this.registration.valid = true;
    },
    goToApp() {
      window.location = '/app';
    },
    goToProfileSettings(tab = null) {
      window.location = `/app/#/settings/${tab}`;
    },
    register() {
      this.registration.loading = true;
      axios.post('/register', this.registration.form)
        .then(res => {
          this.registration.step = 4;
          this.user = res.data.user;
        })
        .catch(err => {
          this.registration.errors = err.response.data.errors;
          this.registration.hasErrors = true;
          this.registration.valid = false;
          this.registration.loading = false;
        })
    },
    joinGroup() {
      this.registration.step = 4;
      axios.post(`/api/group/join/${this.registration.wrapMemberGroup}`);
    },
    resetFieldOnKeypress(field) {
      this.registration.errors[field] = [];
    }
  }
}).$mount('#fudrrApp');
